@import url(~normalize.css);
@import url(~@blueprintjs/core/lib/css/blueprint.css);
@import url(~@blueprintjs/icons/lib/css/blueprint-icons.css);
@import url(~@blueprintjs/datetime/lib/css/blueprint-datetime.css);
.text-purple {
  color: purple; }

.text-yellow {
  color: #f9ac19; }

.btn-back-left {
  position: absolute;
  top: 20px;
  left: 20px; }
  .btn-back-left i {
    color: #f9ac19;
    font-size: 30px; }

.cursor-pointer {
  cursor: pointer; }

@media screen and (max-width: 700px) {
  .btn-back-left {
    top: 10px;
    left: 10px; }
    .btn-back-left i {
      color: black;
      font-size: 25px; } }

.form-control {
  box-shadow: none !important;
  outline: none !important; }
  .form-control:focus {
    box-shadow: none !important;
    outline: none !important; }

.form-check-input {
  background-color: green !important; }

.bottom-mobile-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding: 10px;
  background-color: #ebe7e7;
  z-index: 99; }

.text-danger {
  color: #FF0000 !important; }

.danger-button {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: #ebe7e7;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer; }
  .danger-button .inner-button {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #FF0000; }
  .danger-button:hover {
    transform: scale(1.1); }

.btn-danger {
  background-color: #FF0000 !important; }

.btn-custom-1 {
  background-color: #f9ac19;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid #f9ac19;
  transition: 0.3s; }
  .btn-custom-1:hover {
    background-color: transparent;
    color: #f9ac19; }

.btn-custom-2 {
  background-color: dodgerblue;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid dodgerblue;
  transition: 0.3s; }
  .btn-custom-2:hover {
    background-color: transparent;
    color: dodgerblue; }

.btn-custom-3 {
  background-color: #05c46b;
  color: white;
  border-radius: 3px;
  padding: 10px;
  border: 1.5px solid #05c46b;
  transition: 0.3s; }
  .btn-custom-3:hover {
    background-color: transparent;
    color: #05c46b; }

.btn-disabled {
  background-color: #707070;
  color: white;
  border-radius: 3px;
  padding: 10px; }
  .btn-disabled:hover {
    background-color: #707070;
    color: white; }

.btn-selected {
  background-color: #f9ac19;
  justify-content: center;
  align-items: center;
  display: flex;
  border: none;
  border-radius: 50%; }
  .btn-selected i {
    color: white;
    font-size: 15px; }

.btn-toggle-inactive {
  background-color: #ebe7e7;
  color: white; }
  .btn-toggle-inactive:hover {
    background-color: #ebe7e7;
    color: white; }

.btn-toggle-active {
  background-color: #f9ac19;
  color: white; }
  .btn-toggle-active:hover {
    background-color: #f9ac19;
    color: white; }

.btn-selectable {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #FAFAFA;
  padding: 15px !important; }
  .btn-selectable:hover {
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    outline: none !important;
    box-shadow: none !important;
    background-color: #FAFAFA;
    padding: 15px !important; }

.btn-selectable-active {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  outline: none !important;
  box-shadow: none !important;
  background-color: #39DE3E;
  padding: 15px !important;
  color: white; }
  .btn-selectable-active:hover {
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    outline: none !important;
    box-shadow: none !important;
    background-color: #39DE3E;
    padding: 15px !important;
    color: white; }

.btn {
  outline: none !important;
  box-shadow: none !important; }
  .btn:hover {
    outline: none !important;
    box-shadow: none !important; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

.flex-column-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap; }

.flex-column {
  display: flex;
  justify-content: center;
  flex-direction: column; }

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap; }

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.flex-left-2 {
  display: flex;
  justify-content: flex-start; }

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.flex-between-2 {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.special-navbar {
  background-color: white;
  z-index: 999; }

.side-nav {
  position: fixed;
  width: 300px;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999; }
  .side-nav .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    cursor: pointer;
    animation-name: show-in;
    animation-duration: 0.4s;
    animation-iteration-count: 1; }

@keyframes show-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .side-nav .side-nav-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 300px;
    z-index: 9999999999999 !important;
    background-color: #333333;
    color: white;
    height: 100%;
    animation-name: show-slide;
    animation-duration: 0.4s;
    animation-iteration-count: 1; }

@keyframes show-slide {
  0% {
    transform: translate(-100%, 0); }
  100% {
    transform: translate(0, 0); } }
  .side-nav .logo-section {
    padding: 20px;
    padding-top: 40px; }
    .side-nav .logo-section .logo {
      width: 200px !important; }
  .side-nav .body-section {
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px; }
    .side-nav .body-section .menu-section {
      padding: 0;
      margin: 0;
      list-style: none; }
      .side-nav .body-section .menu-section .menu-item .item-disabled {
        opacity: 0.5; }
      .side-nav .body-section .menu-section .menu-item a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        text-decoration: none; }
        .side-nav .body-section .menu-section .menu-item a span:nth-child(2) {
          margin-left: 10px; }
        .side-nav .body-section .menu-section .menu-item a.is-active span:nth-child(2) {
          color: #f9ac19; }

@media screen and (max-width: 767px) {
  .side-nav {
    position: fixed;
    width: 100vw; } }

.logo {
  padding: 20px;
  padding-left: 150px; }
  .logo img {
    width: 300px;
    cursor: pointer; }

.logo-mudapartner {
  width: 120px;
  border-radius: 50%; }

.panel-padding {
  padding: 40px;
  padding-left: 340px; }

.autocomplete-block {
  position: relative; }
  .autocomplete-block .results {
    position: absolute;
    top: 100%;
    width: 100%;
    background-color: white; }
    .autocomplete-block .results .result-block {
      cursor: pointer;
      padding: 5px; }

.bp3-datepicker {
  transform: scale(1.3); }

.notification-element {
  background-color: #ebe7e7;
  padding: 10px;
  cursor: pointer; }

.notification-component {
  position: relative; }
  .notification-component .notification-counter {
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    background-color: #3b86ff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%; }
    .notification-component .notification-counter p {
      color: white;
      margin: 0 !important; }

.lead-data .bp3-datepicker {
  transform: scale(1) !important; }

.inventory-elements .inventory-element {
  border: none !important;
  padding: 0;
  width: 100px;
  height: 130px; }
  .inventory-elements .inventory-element div {
    width: 100px;
    height: 100px;
    border-radius: 3px;
    position: relative;
    border: 1.5px solid #ebe7e7;
    cursor: pointer; }
    .inventory-elements .inventory-element div .btn-selected {
      background-color: #39DE3E;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      position: absolute;
      left: 100%;
      bottom: 100%;
      transform: translate(-50%, 50%);
      display: flex;
      justify-content: center;
      align-items: center; }
      .inventory-elements .inventory-element div .btn-selected i {
        color: white; }
  .inventory-elements .inventory-element p {
    text-align: center;
    margin: 0;
    margin-top: 4px; }

.photo-elements .photo-element {
  width: 150px;
  height: 150px;
  border-radius: 3px;
  position: relative;
  border: 1.5px solid #ebe7e7; }
  .photo-elements .photo-element .btn-clear-img {
    position: absolute;
    bottom: 100%;
    left: 100%;
    background-color: #f9ac19;
    color: white;
    border: none !important;
    outline: none !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 50%); }
  .photo-elements .photo-element .progress {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    width: 70%; }
    .photo-elements .photo-element .progress .progress-bar {
      background-color: #f9ac19; }

.photo-elements .custom-file {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  cursor: pointer; }
  .photo-elements .custom-file .custom-file-input {
    height: 100%;
    border: none !important;
    background-color: transparent !important;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important; }
  .photo-elements .custom-file .custom-file-label {
    height: 100% !important;
    border: none !important;
    background-color: transparent !important;
    cursor: pointer;
    outline: none !important;
    box-shadow: none !important; }
    .photo-elements .custom-file .custom-file-label:after {
      content: "";
      background-color: transparent;
      border: none !important;
      cursor: pointer;
      outline: none !important;
      box-shadow: none !important; }

.photo-elements .photo-element-add {
  position: relative;
  height: 150px;
  width: 150px;
  border: 1.5px dashed #f9ac19;
  border-radius: 3px;
  cursor: pointer; }
  .photo-elements .photo-element-add i {
    font-size: 35px;
    color: #707070; }

.modal-inventory-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto; }
  .modal-inventory-wrapper .modal-inventory {
    z-index: 10;
    width: 80%;
    overflow: auto;
    background-color: white;
    padding: 40px; }
    .modal-inventory-wrapper .modal-inventory .btn-selectable {
      padding: 10px !important;
      transition: 0.3s; }
      .modal-inventory-wrapper .modal-inventory .btn-selectable:active {
        background-color: #f9ac19; }
        .modal-inventory-wrapper .modal-inventory .btn-selectable:active i {
          color: white; }
    .modal-inventory-wrapper .modal-inventory .modal-element {
      border-bottom: 1px dashed #707070 !important;
      padding: 5px; }
      .modal-inventory-wrapper .modal-inventory .modal-element .quantity {
        font-size: 20px;
        margin: 10px; }
    .modal-inventory-wrapper .modal-inventory .fragile-icon {
      width: 40px;
      height: 50px;
      position: relative;
      border-radius: 3px;
      border: 1px solid #ebe7e7; }
      .modal-inventory-wrapper .modal-inventory .fragile-icon button {
        position: absolute;
        left: 100%;
        bottom: 100%;
        transform: translate(-50%, 50%);
        width: 20px;
        height: 20px; }
    .modal-inventory-wrapper .modal-inventory .fragile-indicator-box {
      border: 2px dashed #f9ac19;
      padding: 15px;
      border-radius: 3px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f9ac19 !important;
  border-color: #f9ac19 !important; }

.navbar {
  z-index: 6 !important;
  background-color: white;
  border-bottom: 1px solid #ebe7e7;
  height: 61.5px;
  position: relative; }
  .navbar .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    .navbar .navbar-brand img {
      width: 100px; }
  .navbar .navbar-nav {
    background-color: white;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1); }
  .navbar a.light {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #b4b4b4 !important;
    cursor: pointer; }
  .navbar a.normal {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #707070 !important; }
  .navbar .navbar-toggler {
    color: #707070; }
    .navbar .navbar-toggler .navbar-toggler-icon {
      color: #707070 !important;
      border-color: #707070 !important; }

.main-header {
  font-size: 30px;
  max-width: 600px;
  font-weight: normal; }

.login, .sign-up {
  background-color: white;
  position: relative;
  padding: 40px; }
  .login .privacy-text, .sign-up .privacy-text {
    line-height: 1.5;
    font-size: 20px;
    text-align: justify; }
  .login .modal-inventory-wrapper, .sign-up .modal-inventory-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto; }
    .login .modal-inventory-wrapper .modal-inventory, .sign-up .modal-inventory-wrapper .modal-inventory {
      z-index: 6;
      width: 80%;
      overflow: auto;
      background-color: white;
      padding: 40px;
      height: 90%; }
      .login .modal-inventory-wrapper .modal-inventory .btn-selectable, .sign-up .modal-inventory-wrapper .modal-inventory .btn-selectable {
        padding: 10px !important;
        transition: 0.3s; }
        .login .modal-inventory-wrapper .modal-inventory .btn-selectable:active, .sign-up .modal-inventory-wrapper .modal-inventory .btn-selectable:active {
          background-color: #f9ac19; }
          .login .modal-inventory-wrapper .modal-inventory .btn-selectable:active i, .sign-up .modal-inventory-wrapper .modal-inventory .btn-selectable:active i {
            color: white; }
      .login .modal-inventory-wrapper .modal-inventory .modal-element, .sign-up .modal-inventory-wrapper .modal-inventory .modal-element {
        border-bottom: 1px dashed #707070 !important;
        padding: 5px; }
        .login .modal-inventory-wrapper .modal-inventory .modal-element .quantity, .sign-up .modal-inventory-wrapper .modal-inventory .modal-element .quantity {
          font-size: 20px;
          margin: 10px; }
      .login .modal-inventory-wrapper .modal-inventory .fragile-icon, .sign-up .modal-inventory-wrapper .modal-inventory .fragile-icon {
        width: 40px;
        height: 50px;
        position: relative;
        border-radius: 3px;
        border: 1px solid #ebe7e7; }
        .login .modal-inventory-wrapper .modal-inventory .fragile-icon button, .sign-up .modal-inventory-wrapper .modal-inventory .fragile-icon button {
          position: absolute;
          left: 100%;
          bottom: 100%;
          transform: translate(-50%, 50%);
          width: 20px;
          height: 20px; }
      .login .modal-inventory-wrapper .modal-inventory .fragile-indicator-box, .sign-up .modal-inventory-wrapper .modal-inventory .fragile-indicator-box {
        border: 2px dashed #f9ac19;
        padding: 15px;
        border-radius: 3px; }
  .login .muda-planner .muda-planner-img img, .sign-up .muda-planner .muda-planner-img img {
    width: 90px;
    height: 90px;
    border-radius: 50%; }
  .login .muda-planner .muda-planner-info h5, .sign-up .muda-planner .muda-planner-info h5 {
    margin: 0; }
  .login .muda-planner .muda-planner-info p, .sign-up .muda-planner .muda-planner-info p {
    font-style: italic; }
  .login .muda-planner .muda-planner-info .muda-planner-stars i, .sign-up .muda-planner .muda-planner-info .muda-planner-stars i {
    color: #f9ac19;
    font-size: 16px; }
  .login .date-picker-wrapper, .sign-up .date-picker-wrapper {
    display: flex;
    justify-content: center;
    padding: 40px;
    border: 1px solid #f1f1f1;
    background-color: #FAFAFA; }
  .login .inventory-elements .inventory-element, .sign-up .inventory-elements .inventory-element {
    border: none !important;
    padding: 0;
    width: 100px;
    height: 130px; }
    .login .inventory-elements .inventory-element div, .sign-up .inventory-elements .inventory-element div {
      width: 100px;
      height: 100px;
      border-radius: 3px;
      position: relative;
      border: 1.5px solid #ebe7e7;
      cursor: pointer; }
      .login .inventory-elements .inventory-element div .btn-selected, .sign-up .inventory-elements .inventory-element div .btn-selected {
        background-color: #39DE3E;
        border: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        left: 100%;
        bottom: 100%;
        transform: translate(-50%, 50%);
        display: flex;
        justify-content: center;
        align-items: center; }
        .login .inventory-elements .inventory-element div .btn-selected i, .sign-up .inventory-elements .inventory-element div .btn-selected i {
          color: white; }
    .login .inventory-elements .inventory-element p, .sign-up .inventory-elements .inventory-element p {
      text-align: center;
      margin: 0;
      margin-top: 4px; }
  .login .photo-elements .photo-element, .sign-up .photo-elements .photo-element {
    width: 150px;
    height: 150px;
    border-radius: 3px;
    position: relative;
    border: 1.5px solid #ebe7e7; }
    .login .photo-elements .photo-element .btn-clear-img, .sign-up .photo-elements .photo-element .btn-clear-img {
      position: absolute;
      bottom: 100%;
      left: 100%;
      background-color: #f9ac19;
      color: white;
      border: none !important;
      outline: none !important;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(-50%, 50%); }
    .login .photo-elements .photo-element .progress, .sign-up .photo-elements .photo-element .progress {
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 2;
      width: 70%; }
      .login .photo-elements .photo-element .progress .progress-bar, .sign-up .photo-elements .photo-element .progress .progress-bar {
        background-color: #f9ac19; }
  .login .photo-elements .custom-file, .sign-up .photo-elements .custom-file {
    position: absolute;
    top: 0;
    left: 0;
    height: 100px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    cursor: pointer; }
    .login .photo-elements .custom-file .custom-file-input, .sign-up .photo-elements .custom-file .custom-file-input {
      height: 100%;
      border: none !important;
      background-color: transparent !important;
      cursor: pointer;
      outline: none !important;
      box-shadow: none !important; }
    .login .photo-elements .custom-file .custom-file-label, .sign-up .photo-elements .custom-file .custom-file-label {
      height: 100% !important;
      border: none !important;
      background-color: transparent !important;
      cursor: pointer;
      outline: none !important;
      box-shadow: none !important; }
      .login .photo-elements .custom-file .custom-file-label:after, .sign-up .photo-elements .custom-file .custom-file-label:after {
        content: "";
        background-color: transparent;
        border: none !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
  .login .photo-elements .photo-element-add, .sign-up .photo-elements .photo-element-add {
    position: relative;
    height: 150px;
    width: 150px;
    border: 1.5px dashed #f9ac19;
    border-radius: 3px;
    cursor: pointer; }
    .login .photo-elements .photo-element-add i, .sign-up .photo-elements .photo-element-add i {
      font-size: 35px;
      color: #707070; }
  .login .login-form, .sign-up .login-form {
    width: 350px; }
    .login .login-form .forgot-passowrd, .sign-up .login-form .forgot-passowrd {
      font-size: 12px;
      display: block; }
    .login .login-form .create-account, .sign-up .login-form .create-account {
      display: block;
      font-size: 12px; }
      .login .login-form .create-account span, .sign-up .login-form .create-account span {
        color: #059109; }
    .login .login-form .form-group .input-group-text, .sign-up .login-form .form-group .input-group-text {
      border: 1px solid #f1f1f1;
      background-color: #FAFAFA; }
      .login .login-form .form-group .input-group-text .checked, .sign-up .login-form .form-group .input-group-text .checked {
        color: #39DE3E; }
    .login .login-form .form-group .form-control, .sign-up .login-form .form-group .form-control {
      border: 1px solid #f1f1f1;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: #FAFAFA;
      padding: 22px !important; }
  .login .other-wrapper, .sign-up .other-wrapper {
    min-width: 450px;
    padding: 40px;
    border: 1px solid #f1f1f1;
    background-color: #FAFAFA; }
    .login .other-wrapper button, .sign-up .other-wrapper button {
      background-color: white; }
  .login .floor-wrapper, .sign-up .floor-wrapper {
    padding: 40px;
    border: 1px solid #f1f1f1;
    background-color: #FAFAFA; }
    .login .floor-wrapper h6, .sign-up .floor-wrapper h6 {
      font-weight: normal;
      font-size: 20px;
      margin: 0; }
    .login .floor-wrapper button, .sign-up .floor-wrapper button {
      background-color: white; }

.card-element {
  border-radius: 10px;
  background-color: #FAFAFA;
  padding: 20px; }

.color-primary {
  color: #f9ac19; }

.italic {
  font-style: italic; }

.home-main {
  padding: 40px;
  z-index: 3; }
  .home-main .step-circle {
    width: 25px;
    height: 25px;
    background-color: #707070;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center; }
  .home-main .btn-custom-1 {
    max-width: 300px !important; }
  .home-main .btn-selectable {
    max-width: 300px; }
  .home-main .step-icon {
    color: #707070;
    font-size: 25px !important; }

.account-functions a.light {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #b4b4b4 !important;
  cursor: pointer; }

.account-functions a.normal {
  margin: 0;
  padding: 0;
  font-weight: bold; }

.question-block {
  background-color: #f9ac19;
  padding: 10px;
  max-width: 500px;
  min-width: 500px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.3s; }
  .question-block.question-selected {
    background-color: #707070; }
    .question-block.question-selected p {
      font-size: 14px; }
    .question-block.question-selected .answer-block p {
      font-size: 14px; }
  .question-block p {
    color: white;
    margin: 0;
    padding: 0;
    text-align: center;
    transition: 0.3s;
    font-size: 16px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none; }
  .question-block .answer-block {
    z-index: 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2); }
    .question-block .answer-block p {
      margin: 0;
      padding: 0;
      color: #707070;
      text-align: center;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none; }
  .question-block .block-in {
    animation-name: block-in;
    animation-duration: 0.5s;
    animation-iteration-count: 1; }

@keyframes block-in {
  0% {
    transform-origin: top;
    transform: scaleY(0);
    opacity: 0; }
  100% {
    transform: scaleY(1);
    opacity: 1;
    p {
      opacity: 1; } } }

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 40px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto; }
  .modal-wrapper hr {
    width: 100% !important;
    background-color: #f9ac19; }
  .modal-wrapper .selectable-block {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 3px dashed darkgray; }
  .modal-wrapper .form-iva {
    border-color: #f9ac19; }
  .modal-wrapper .btn-close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 13;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    animation-name: show-in-late;
    animation-iteration-count: 1;
    animation-duration: 1s; }

@keyframes show-in-late {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .modal-wrapper .btn-close-modal i {
      color: #707070 !important;
      font-size: 30px; }
    .modal-wrapper .btn-close-modal:active {
      border: none !important;
      outline: none !important; }
    .modal-wrapper .btn-close-modal:hover {
      border: none !important;
      outline: none !important; }
  .modal-wrapper .modal-content {
    z-index: 12;
    width: 80%;
    overflow: auto;
    background-color: white;
    padding: 40px !important;
    height: 90%; }
    .modal-wrapper .modal-content .dashed {
      border-bottom: 2px dashed #ebe7e7 !important;
      background-color: white;
      border-left: none !important;
      border-top: none !important;
      border-right: none !important; }
    .modal-wrapper .modal-content .custom-select:focus {
      border-color: #f9ac19; }
    .modal-wrapper .modal-content .custom-select:active {
      border-color: #f9ac19; }
    .modal-wrapper .modal-content .DayPicker-Day--selected {
      background-color: #f9ac19; }
      .modal-wrapper .modal-content .DayPicker-Day--selected:hover {
        background-color: #f9ac19; }
    .modal-wrapper .modal-content .text-1 {
      font-size: 20px;
      color: #05c46b; }
    .modal-wrapper .modal-content .text-3 {
      font-size: 25px;
      color: #0770cc; }
    .modal-wrapper .modal-content .text-4 {
      font-size: 20px;
      color: #f9ac19; }
    .modal-wrapper .modal-content .text-2 {
      font-size: 18px; }
    .modal-wrapper .modal-content .btn-iva {
      border-color: #f9ac19 !important;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      background-color: white !important; }
      .modal-wrapper .modal-content .btn-iva i {
        color: #707070; }

.modal-home-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  overflow: auto;
  z-index: 6;
  color: black;
  z-index: 99999 !important;
  animation-name: show-modal;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes show-modal {
  0% {
    top: 100%; }
  100% {
    top: 0; } }
  .modal-home-wrapper .btn-close-modal {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 13;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    animation-name: show-in-late;
    animation-iteration-count: 1;
    animation-duration: 1s; }

@keyframes show-in-late {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .modal-home-wrapper .btn-close-modal i {
      color: #707070 !important;
      font-size: 30px; }
    .modal-home-wrapper .btn-close-modal:active {
      border: none !important;
      outline: none !important; }
    .modal-home-wrapper .btn-close-modal:hover {
      border: none !important;
      outline: none !important; }
  .modal-home-wrapper .modal-home {
    width: 60%;
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    border-radius: 10px;
    position: relative;
    z-index: 6;
    overflow: auto;
    height: 90%;
    z-index: 99999 !important; }
    .modal-home-wrapper .modal-home .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home .action-text h1 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home .field-element a.a-value {
        display: block; }
  .modal-home-wrapper .mudatodo-img img {
    width: 100px; }
  .modal-home-wrapper .modal-home-company-2 {
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    padding-bottom: 40px;
    min-width: 400px;
    border-radius: 10px;
    position: relative;
    z-index: 6; }
    .modal-home-wrapper .modal-home-company-2 .form-group .input-group-text {
      border: 1px solid #f1f1f1;
      background-color: #FAFAFA; }
      .modal-home-wrapper .modal-home-company-2 .form-group .input-group-text .checked {
        color: #39DE3E; }
    .modal-home-wrapper .modal-home-company-2 .form-group .form-control {
      border: 1px solid #f1f1f1;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: #FAFAFA;
      padding: 22px !important; }
    .modal-home-wrapper .modal-home-company-2 .company-logo-absolute {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%); }
      .modal-home-wrapper .modal-home-company-2 .company-logo-absolute img {
        width: 80px; }
    .modal-home-wrapper .modal-home-company-2 .modal-home-text {
      font-size: 20px;
      margin: 0; }
    .modal-home-wrapper .modal-home-company-2 .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home-company-2 .action-text h4 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home-company-2 .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home-company-2 .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company-2 .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company-2 .field-element a.a-value {
        display: block; }
  .modal-home-wrapper .modal-home-company {
    background-color: white;
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 40px;
    padding-bottom: 100px;
    min-width: 400px;
    border-radius: 10px;
    position: relative;
    z-index: 6; }
    .modal-home-wrapper .modal-home-company .form-group .input-group-text {
      border: 1px solid #f1f1f1;
      background-color: #FAFAFA; }
      .modal-home-wrapper .modal-home-company .form-group .input-group-text .checked {
        color: #39DE3E; }
    .modal-home-wrapper .modal-home-company .form-group .form-control {
      border: 1px solid #f1f1f1;
      border-radius: 3px;
      outline: none !important;
      box-shadow: none !important;
      background-color: #FAFAFA;
      padding: 22px !important; }
    .modal-home-wrapper .modal-home-company .company-logo-absolute {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, 50%); }
      .modal-home-wrapper .modal-home-company .company-logo-absolute img {
        width: 80px; }
    .modal-home-wrapper .modal-home-company .modal-home-text {
      font-size: 20px;
      margin: 0; }
    .modal-home-wrapper .modal-home-company .action-text {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      border-top: 1px solid #ebe7e7;
      cursor: pointer; }
      .modal-home-wrapper .modal-home-company .action-text h4 {
        color: #f9ac19;
        padding: 0;
        margin: 0; }
    .modal-home-wrapper .modal-home-company .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .modal-home-wrapper .modal-home-company .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company .field-element .value {
        padding: 0;
        margin: 0; }
      .modal-home-wrapper .modal-home-company .field-element a.a-value {
        display: block; }

.messenger {
  padding: 20px;
  background-color: whitesmoke; }
  .messenger .main-columns {
    height: 100%; }
  .messenger .payment-info {
    padding: 10px;
    border: 1.5px solid dodgerblue;
    background-color: white; }
    .messenger .payment-info .total {
      font-size: 40px; }
    .messenger .payment-info .bank-details {
      border: 3px solid #16a085;
      padding: 10px; }
  .messenger .left-section .input-group {
    border: 1.5px solid #707070; }
    .messenger .left-section .input-group .form-control {
      border: none !important;
      outline: none !important;
      box-shadow: none !important; }
      .messenger .left-section .input-group .form-control:focus {
        outline: none !important;
        box-shadow: none !important; }
    .messenger .left-section .input-group .input-group-append {
      background-color: white; }
  .messenger .left-section .chat-element {
    background-color: #e7eff9;
    padding: 15px;
    cursor: pointer;
    transition: 0.3s;
    position: relative; }
    .messenger .left-section .chat-element.active {
      background-color: #f5f2e1; }
    .messenger .left-section .chat-element .image-circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: white; }
    .messenger .left-section .chat-element .unread {
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: 30px;
      height: 30px;
      background-color: #3b86ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      animation-name: attention;
      animation-duration: 0.5s;
      animation-iteration-count: infinite; }
      .messenger .left-section .chat-element .unread p {
        color: white; }

@keyframes attention {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }
  .messenger .middle-section .messages-box {
    border: 1.5px solid #707070;
    background-color: white;
    position: relative; }
    .messenger .middle-section .messages-box .messages-box-container {
      position: absolute;
      padding: 10px;
      bottom: 0 !important;
      left: 0 !important;
      overflow: auto;
      width: 100%;
      max-height: 100%; }
      .messenger .middle-section .messages-box .messages-box-container .message-item.system {
        color: black;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end; }
        .messenger .middle-section .messages-box .messages-box-container .message-item.system .message-item-box {
          padding: 20px;
          background-color: #edf0f5;
          border-radius: 10px;
          max-width: 100%;
          width: 100%;
          position: relative; }
          .messenger .middle-section .messages-box .messages-box-container .message-item.system .message-item-box .time {
            position: absolute;
            top: 100%;
            left: 0;
            color: #707070;
            font-size: 12px; }
      .messenger .middle-section .messages-box .messages-box-container .message-item.owned {
        color: white;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end; }
        .messenger .middle-section .messages-box .messages-box-container .message-item.owned .message-item-image {
          width: 40px;
          height: 40px;
          background-color: #3b86ff;
          border-radius: 50%; }
        .messenger .middle-section .messages-box .messages-box-container .message-item.owned .message-item-box {
          padding: 20px;
          background-color: #3b86ff;
          border-radius: 10px;
          border-bottom-right-radius: 0 !important;
          max-width: 80%;
          position: relative; }
          .messenger .middle-section .messages-box .messages-box-container .message-item.owned .message-item-box .time {
            position: absolute;
            top: 100%;
            right: 0;
            color: #707070;
            font-size: 12px; }
      .messenger .middle-section .messages-box .messages-box-container .message-item.other {
        display: flex;
        justify-content: left; }
        .messenger .middle-section .messages-box .messages-box-container .message-item.other .message-item-image {
          width: 40px;
          height: 40px;
          background-color: #edf0f5;
          border-radius: 50%; }
        .messenger .middle-section .messages-box .messages-box-container .message-item.other .message-item-box {
          padding: 20px;
          background-color: #edf0f5;
          border-radius: 10px;
          border-top-left-radius: 0 !important;
          max-width: 80%;
          position: relative; }
          .messenger .middle-section .messages-box .messages-box-container .message-item.other .message-item-box .time {
            position: absolute;
            top: 100%;
            left: 0;
            color: #707070;
            font-size: 12px; }
  .messenger .middle-section .input-message-container .btn-file {
    overflow: hidden !important; }
    .messenger .middle-section .input-message-container .btn-file .custom-file {
      position: absolute;
      top: 0;
      left: 0;
      height: 100% !important;
      max-width: 100%;
      width: 100%;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      background-color: transparent !important;
      cursor: pointer;
      max-height: 100%; }
      .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-input {
        height: 100%;
        width: 100%;
        max-width: 100%;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
      .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-label {
        height: 100% !important;
        border: none !important;
        max-height: 100%;
        max-width: 100%;
        background-color: transparent !important;
        cursor: pointer;
        outline: none !important;
        box-shadow: none !important; }
        .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-label:after {
          content: "";
          background-color: transparent;
          border: none !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important;
          max-width: 100%;
          width: 100%;
          height: 100%;
          max-height: 100%; }
  .messenger .middle-section .input-group .form-control {
    border-radius: 0;
    box-shadow: none !important;
    outline: none !important;
    border-color: #f9ac19; }
    .messenger .middle-section .input-group .form-control:focus {
      box-shadow: 0 !important;
      outline: none !important; }
  .messenger .middle-section .input-group .input-group-append {
    border: 1.5px solid #707070;
    height: 38px;
    margin-left: 10px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important; }
    .messenger .middle-section .input-group .input-group-append .btn {
      padding-top: 5px !important;
      padding-bottom: 5px !important; }
  .messenger .right-section {
    overflow: auto !important; }
    .messenger .right-section .right-section-content {
      overflow: auto;
      height: auto !important;
      max-height: auto !important; }

.messenger-2 {
  padding: 20px;
  background-color: whitesmoke; }
  .messenger-2 .main-columns {
    height: 100%; }
  .messenger-2 .payment-info {
    padding: 10px;
    border: 1.5px solid dodgerblue;
    background-color: white; }
    .messenger-2 .payment-info .total {
      font-size: 40px; }
    .messenger-2 .payment-info .bank-details {
      border: 3px solid #16a085;
      padding: 10px; }
  .messenger-2 .left-section .input-group {
    border: 1.5px solid #707070; }
    .messenger-2 .left-section .input-group .form-control {
      border: none !important;
      outline: none !important;
      box-shadow: none !important; }
      .messenger-2 .left-section .input-group .form-control:focus {
        outline: none !important;
        box-shadow: none !important; }
    .messenger-2 .left-section .input-group .input-group-append {
      background-color: white; }
  .messenger-2 .left-section .chat-element {
    background-color: #e7eff9;
    padding: 15px;
    cursor: pointer;
    transition: 0.3s;
    position: relative; }
    .messenger-2 .left-section .chat-element.active {
      background-color: #f5f2e1; }
    .messenger-2 .left-section .chat-element .image-circle {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: white; }
    .messenger-2 .left-section .chat-element .unread {
      position: absolute;
      right: 5px;
      bottom: 5px;
      width: 30px;
      height: 30px;
      background-color: #3b86ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      animation-name: attention;
      animation-duration: 0.5s;
      animation-iteration-count: infinite; }
      .messenger-2 .left-section .chat-element .unread p {
        color: white; }

@keyframes attention {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }
  .messenger-2 .middle-section {
    width: 100%; }
    .messenger-2 .middle-section .messages-box {
      border: 1.5px solid #707070;
      background-color: white;
      position: relative;
      width: 100%; }
      .messenger-2 .middle-section .messages-box .messages-box-container {
        position: absolute;
        padding: 10px;
        bottom: 0 !important;
        left: 0 !important;
        overflow: auto;
        width: 100%;
        max-height: 100%; }
        .messenger-2 .middle-section .messages-box .messages-box-container .message-item.system {
          color: black;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end; }
          .messenger-2 .middle-section .messages-box .messages-box-container .message-item.system .message-item-box {
            padding: 20px;
            background-color: #edf0f5;
            border-radius: 10px;
            max-width: 100%;
            width: 100%;
            position: relative; }
            .messenger-2 .middle-section .messages-box .messages-box-container .message-item.system .message-item-box .time {
              position: absolute;
              top: 100%;
              left: 0;
              color: #707070;
              font-size: 12px; }
        .messenger-2 .middle-section .messages-box .messages-box-container .message-item.owned {
          color: white;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end; }
          .messenger-2 .middle-section .messages-box .messages-box-container .message-item.owned .message-item-image {
            width: 40px;
            height: 40px;
            background-color: #3b86ff;
            border-radius: 50%; }
          .messenger-2 .middle-section .messages-box .messages-box-container .message-item.owned .message-item-box {
            padding: 20px;
            background-color: #3b86ff;
            border-radius: 10px;
            border-bottom-right-radius: 0 !important;
            max-width: 80%;
            position: relative; }
            .messenger-2 .middle-section .messages-box .messages-box-container .message-item.owned .message-item-box .time {
              position: absolute;
              top: 100%;
              right: 0;
              color: #707070;
              font-size: 12px; }
        .messenger-2 .middle-section .messages-box .messages-box-container .message-item.other {
          display: flex;
          justify-content: left; }
          .messenger-2 .middle-section .messages-box .messages-box-container .message-item.other .message-item-image {
            width: 40px;
            height: 40px;
            background-color: #edf0f5;
            border-radius: 50%; }
          .messenger-2 .middle-section .messages-box .messages-box-container .message-item.other .message-item-box {
            padding: 20px;
            background-color: #edf0f5;
            border-radius: 10px;
            border-top-left-radius: 0 !important;
            max-width: 80%;
            position: relative; }
            .messenger-2 .middle-section .messages-box .messages-box-container .message-item.other .message-item-box .time {
              position: absolute;
              top: 100%;
              left: 0;
              color: #707070;
              font-size: 12px; }
    .messenger-2 .middle-section .input-message-container .btn-file {
      overflow: hidden !important; }
      .messenger-2 .middle-section .input-message-container .btn-file .custom-file {
        position: absolute;
        top: 0;
        left: 0;
        height: 100% !important;
        max-width: 100%;
        width: 100%;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: pointer;
        max-height: 100%; }
        .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-input {
          height: 100%;
          width: 100%;
          max-width: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
        .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-label {
          height: 100% !important;
          border: none !important;
          max-height: 100%;
          max-width: 100%;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
          .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-label:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
            outline: none !important;
            box-shadow: none !important;
            max-width: 100%;
            width: 100%;
            height: 100%;
            max-height: 100%; }
    .messenger-2 .middle-section .input-group .form-control {
      border-radius: 0;
      box-shadow: none !important;
      outline: none !important;
      border-color: #f9ac19; }
      .messenger-2 .middle-section .input-group .form-control:focus {
        box-shadow: 0 !important;
        outline: none !important; }
    .messenger-2 .middle-section .input-group .input-group-append {
      border: 1.5px solid #707070;
      height: 38px;
      margin-left: 10px;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important; }
      .messenger-2 .middle-section .input-group .input-group-append .btn {
        padding-top: 5px !important;
        padding-bottom: 5px !important; }
  .messenger-2 .right-section {
    overflow: auto !important; }
    .messenger-2 .right-section .right-section-content {
      overflow: auto;
      height: auto !important;
      max-height: auto !important; }

.proposal-block {
  padding: 20px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 10px;
  min-width: 300px;
  border-bottom: 1.5px solid #ebe7e7; }
  .proposal-block .picture {
    width: 60px; }
  .proposal-block .company-name {
    font-size: 18px;
    margin: 0;
    padding: 0; }
  .proposal-block .offer {
    font-size: 20px;
    margin: 0;
    padding: 0; }

.proposals {
  padding: 40px; }
  .proposals .proposal-item {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 2px 5px 5px 2px rgba(0, 0, 0, 0.1);
    min-width: 300px; }
    .proposals .proposal-item .picture {
      width: 60px; }
    .proposals .proposal-item .company-name {
      font-size: 18px;
      margin: 0;
      padding: 0; }
    .proposals .proposal-item .offer {
      font-size: 20px;
      margin: 0;
      padding: 0; }

.proposal-viewer {
  padding-bottom: 40px; }
  .proposal-viewer .field-element {
    padding: 10px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px dashed #707070; }
    .proposal-viewer .field-element .header {
      font-size: 20px;
      padding: 0;
      margin: 0; }
    .proposal-viewer .field-element .value {
      padding: 0;
      margin: 0; }
    .proposal-viewer .field-element a.a-value {
      display: block; }
  .proposal-viewer .quotation .proposal-viewer-item {
    padding: 10px;
    border-bottom: 2px dashed #ebe7e7; }
    .proposal-viewer .quotation .proposal-viewer-item .header {
      font-weight: bold;
      font-size: 20px;
      margin: 0;
      padding: 0; }
    .proposal-viewer .quotation .proposal-viewer-item .value {
      margin: 0;
      padding: 0;
      margin-top: 7px;
      font-size: 18px; }
    .proposal-viewer .quotation .proposal-viewer-item .value-big {
      margin: 0;
      padding: 0;
      margin-top: 7px;
      font-size: 20px; }
    .proposal-viewer .quotation .proposal-viewer-item .total {
      font-size: 30px;
      color: dodgerblue; }
    .proposal-viewer .quotation .proposal-viewer-item .money {
      font-size: 25px; }
    .proposal-viewer .quotation .proposal-viewer-item .money-primary {
      font-size: 25px;
      color: #f9ac19; }
  .proposal-viewer .driver-block-wrapper {
    overflow: auto; }
    .proposal-viewer .driver-block-wrapper .driver-block {
      background-color: #ebe7e7;
      padding: 20px; }
      .proposal-viewer .driver-block-wrapper .driver-block .driver-logo {
        width: 80px; }
      .proposal-viewer .driver-block-wrapper .driver-block .validity {
        font-size: 20px; }
      .proposal-viewer .driver-block-wrapper .driver-block .company-name {
        font-size: 20px;
        color: #707070; }
      .proposal-viewer .driver-block-wrapper .driver-block .counter span {
        font-size: 25px !important;
        letter-spacing: 4px;
        font-weight: bold !important; }
      .proposal-viewer .driver-block-wrapper .driver-block .counter-text span {
        font-size: 17px;
        color: #707070; }
  .proposal-viewer .comment-section {
    padding: 40px;
    background-color: #ebe7e7;
    padding-left: 80px;
    padding-right: 80px; }
    .proposal-viewer .comment-section h5 {
      font-weight: normal; }
    .proposal-viewer .comment-section .custom-right-arrow {
      position: absolute !important;
      right: 0em;
      top: 50%;
      z-index: 1;
      display: inline-block;
      color: #c8c8c8;
      padding: 6px;
      opacity: 0.8;
      cursor: pointer;
      font-weight: lighter;
      font-size: 50px;
      transform: translate(0, -50%); }
    .proposal-viewer .comment-section .custom-right-arrow:hover {
      opacity: 0.5; }
    .proposal-viewer .comment-section .custom-left-arrow {
      position: absolute !important;
      left: 0em;
      top: 50%;
      z-index: 1;
      display: inline-block;
      padding: 6px;
      opacity: 0.8;
      cursor: pointer;
      font-size: 50px;
      font-weight: lighter;
      color: #c8c8c8;
      transform: translate(0, -50%); }
    .proposal-viewer .comment-section .custom-left-arrow:hover {
      opacity: 0.5; }

.control-arrow {
  background-color: transparent !important;
  color: black !important;
  opacity: 0.3 !important; }

.control-next::before {
  border-color: transparent yellow transparent black !important;
  border-width: 15px !important; }

.control-prev::before {
  border-color: transparent black transparent black !important;
  border-width: 15px !important; }

.payment-module .main-text {
  font-size: 25px;
  max-width: 400px; }

.payment-module .details-payment {
  text-align: center;
  font-size: 20px; }

.payment-module .header-primary {
  color: #f9ac19;
  font-size: 35px; }

.payment-module .insurance {
  width: 90px; }

.payment-module .insurance-description {
  font-size: 16px;
  max-width: 600px; }

.payment-module .money {
  font-size: 20px; }

.payment-module .btn-custom-2 {
  width: 250px; }

.payment-module .payment-type {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  min-height: 80px;
  justify-content: center;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.1);
  min-width: 300px; }
  .payment-module .payment-type .col-9 p {
    margin: 0;
    padding: 0; }

.hr-mobile {
  display: none; }

@media screen and (max-width: 767px) {
  .modal-wrapper {
    padding: 0 !important; }
    .modal-wrapper .modal-content {
      width: 100% !important;
      height: 100% !important; }
      .modal-wrapper .modal-content .dashed {
        border-bottom: 2px dashed #ebe7e7 !important;
        background-color: white;
        border-left: none !important;
        border-top: none !important;
        border-right: none !important; }
  .hr-mobile {
    background-color: #f9ac19;
    display: block;
    margin-left: 90px;
    margin-right: 90px; }
  .payment-module {
    padding: 20px; }
    .payment-module .main-text {
      font-size: 25px;
      max-width: 300px; }
    .payment-module .header-primary {
      color: #f9ac19;
      font-size: 35px; }
    .payment-module .insurance {
      width: 90px; }
    .payment-module .insurance-description {
      font-size: 16px;
      max-width: 300px; }
    .payment-module .money {
      font-size: 20px; }
    .payment-module .btn-custom-2 {
      width: 250px; }
  .modal-home-wrapper {
    overflow: auto;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
    z-index: 4;
    padding: 0;
    animation-name: show-modal;
    animation-duration: 0.5s;
    animation-iteration-count: 1; }
  @keyframes show-modal {
    0% {
      top: 100%; }
    100% {
      top: 0; } }
    .modal-home-wrapper .btn-close-modal {
      position: fixed;
      top: 20px;
      right: 20px;
      background-color: white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      z-index: 13;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none !important;
      animation-name: show-in-late;
      animation-iteration-count: 1;
      animation-duration: 1s; }
  @keyframes show-in-late {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
      .modal-home-wrapper .btn-close-modal i {
        color: #707070 !important;
        font-size: 30px; }
      .modal-home-wrapper .btn-close-modal:active {
        border: none !important;
        outline: none !important; }
      .modal-home-wrapper .btn-close-modal:hover {
        border: none !important;
        outline: none !important; }
    .modal-home-wrapper .modal-home {
      width: 100%;
      background-color: white;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
      padding: 20px;
      padding-bottom: 80px;
      position: relative;
      z-index: 6;
      border-radius: 0;
      height: 100%;
      overflow: auto; }
      .modal-home-wrapper .modal-home .link {
        text-decoration: underline;
        color: dodgerblue; }
      .modal-home-wrapper .modal-home .action-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid #ebe7e7;
        cursor: pointer; }
        .modal-home-wrapper .modal-home .action-text h1 {
          color: #f9ac19;
          padding: 0;
          margin: 0; }
      .modal-home-wrapper .modal-home .field-element {
        padding: 10px;
        border-bottom: 1px dashed #707070; }
        .modal-home-wrapper .modal-home .field-element .header {
          font-size: 20px;
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home .field-element .value {
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home .field-element a.a-value {
          display: block; }
    .modal-home-wrapper .modal-home-company {
      background-color: white;
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
      padding: 20px;
      padding-bottom: 100px;
      border-radius: 10px;
      position: relative;
      z-index: 6; }
      .modal-home-wrapper .modal-home-company .action-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        border-top: 1px solid #ebe7e7;
        cursor: pointer; }
        .modal-home-wrapper .modal-home-company .action-text h4 {
          color: #f9ac19;
          padding: 0;
          margin: 0; }
      .modal-home-wrapper .modal-home-company .field-element {
        padding: 10px;
        border-bottom: 1px dashed #707070; }
        .modal-home-wrapper .modal-home-company .field-element .header {
          font-size: 20px;
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home-company .field-element .value {
          padding: 0;
          margin: 0; }
        .modal-home-wrapper .modal-home-company .field-element a.a-value {
          display: block; }
  .proposal-viewer {
    animation-name: show-proposals-viewer;
    animation-iteration-count: 1;
    animation-duration: 0.5s; }
    .proposal-viewer .request {
      padding: 20px; }
    .proposal-viewer .details {
      padding: 20px; }
    .proposal-viewer .field-element {
      padding: 10px;
      border-bottom: 1px dashed #707070; }
      .proposal-viewer .field-element .header {
        font-size: 20px;
        padding: 0;
        margin: 0; }
      .proposal-viewer .field-element .value {
        padding: 0;
        margin: 0; }
      .proposal-viewer .field-element a.a-value {
        display: block; }
    .proposal-viewer .comment-section {
      padding: 20px; }
      .proposal-viewer .comment-section h5 {
        font-size: 18px; }
    .proposal-viewer .quotation {
      padding: 10px; }
      .proposal-viewer .quotation .proposal-viewer-item {
        padding: 10px;
        border-bottom: 2px dashed #ebe7e7; }
        .proposal-viewer .quotation .proposal-viewer-item .header {
          font-weight: bold;
          font-size: 20px;
          margin: 0;
          padding: 0; }
        .proposal-viewer .quotation .proposal-viewer-item .value {
          margin: 0;
          padding: 0;
          margin-top: 7px;
          font-size: 18px; }
        .proposal-viewer .quotation .proposal-viewer-item .value-big {
          margin: 0;
          padding: 0;
          margin-top: 7px;
          font-size: 20px; }
        .proposal-viewer .quotation .proposal-viewer-item .total {
          font-size: 30px;
          color: dodgerblue; }
        .proposal-viewer .quotation .proposal-viewer-item .money {
          font-size: 25px; }
        .proposal-viewer .quotation .proposal-viewer-item .money-primary {
          font-size: 25px;
          color: #f9ac19; }
    .proposal-viewer .driver-block-wrapper {
      padding: 0px; }
      .proposal-viewer .driver-block-wrapper .driver-block {
        background-color: #ebe7e7;
        padding: 20px; }
        .proposal-viewer .driver-block-wrapper .driver-block .driver-logo {
          width: 80px; }
        .proposal-viewer .driver-block-wrapper .driver-block .validity {
          font-size: 20px; }
        .proposal-viewer .driver-block-wrapper .driver-block .company-name {
          font-size: 20px;
          color: #707070; }
        .proposal-viewer .driver-block-wrapper .driver-block .counter span {
          font-size: 25px !important;
          letter-spacing: 4px;
          font-weight: bold !important; }
  .home-main {
    padding: 10px; }
  .account-functions a.light {
    margin: 0;
    padding: 0;
    font-weight: bold;
    color: #b4b4b4 !important;
    cursor: pointer; }
  .account-functions a.normal {
    margin: 0;
    padding: 0;
    font-weight: bold; }
  .question-block {
    min-width: 250px;
    max-width: 250px; }
  .floor-wrapper {
    padding: 10px !important;
    border: 1px solid #f1f1f1;
    background-color: #FAFAFA; }
    .floor-wrapper h6 {
      font-weight: normal;
      font-size: 20px;
      margin: 0; }
    .floor-wrapper button {
      background-color: white; }
  .other-wrapper {
    min-width: 300px !important;
    padding: 10px !important;
    border: 1px solid #f1f1f1;
    background-color: #FAFAFA; }
    .other-wrapper button {
      background-color: white; }
  .logo {
    padding-left: 20px;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #ebe7e7; }
    .logo img {
      width: 200px; }
  .login, .sign-up {
    padding: 20px; }
  .main-header {
    font-size: 20px;
    background-color: #ebe7e7;
    padding: 15px;
    border-radius: 10px; }
  .login-form {
    max-width: 300px; }
    .login-form .form-control {
      max-width: 100%; }
  .modal-inventory-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto; }
    .modal-inventory-wrapper .modal-inventory {
      z-index: 3;
      width: 100% !important;
      overflow: auto;
      background-color: white;
      padding: 10px;
      height: 100% !important; }
      .modal-inventory-wrapper .modal-inventory .flex-between {
        flex-direction: column;
        justify-content: center; }
      .modal-inventory-wrapper .modal-inventory .flex-end {
        justify-content: center !important; }
      .modal-inventory-wrapper .modal-inventory .btn-selectable {
        padding: 10px !important;
        transition: 0.3s; }
        .modal-inventory-wrapper .modal-inventory .btn-selectable:active {
          background-color: #f9ac19; }
          .modal-inventory-wrapper .modal-inventory .btn-selectable:active i {
            color: white; }
      .modal-inventory-wrapper .modal-inventory .modal-element {
        border-bottom: 1px dashed #707070 !important;
        padding: 5px; }
        .modal-inventory-wrapper .modal-inventory .modal-element .quantity {
          font-size: 20px;
          margin: 10px; }
      .modal-inventory-wrapper .modal-inventory .fragile-icon {
        width: 40px;
        height: 50px;
        position: relative;
        border-radius: 3px;
        border: 1px solid #ebe7e7; }
        .modal-inventory-wrapper .modal-inventory .fragile-icon button {
          position: absolute;
          left: 100%;
          bottom: 100%;
          transform: translate(-50%, 50%);
          width: 20px;
          height: 20px; }
      .modal-inventory-wrapper .modal-inventory .fragile-indicator-box {
        border: 2px dashed #f9ac19;
        padding: 15px;
        border-radius: 3px; } }

@media screen and (max-width: 767px) {
  .logo-mudapartner {
    width: 80px;
    border-radius: 50%; }
  .row .col-sm-7 {
    padding: 0 !important; }
  .row .col-sm-5 {
    padding: 0 !important; }
  .panel-padding {
    padding: 20px;
    padding-left: 20px;
    padding-bottom: 80px; }
  .autocomplete-block {
    position: relative !important; }
    .autocomplete-block .results {
      position: absolute !important;
      top: 100%;
      width: 100%;
      background-color: white;
      z-index: 15 !important; }
      .autocomplete-block .results .result-block {
        cursor: pointer;
        padding: 5px !important; }
  .right-section-content {
    padding: 10px;
    border: 1.5px solid #707070; }
  .modal-home-wrapper {
    z-index: 9 !important; }
  .modal-inventory-wrapper {
    z-index: 10 !important; }
  .messenger {
    padding: 0 !important; }
    .messenger .left-section {
      background-color: white;
      padding: 10px !important; }
    .messenger .right-section {
      background-color: white;
      padding: 10px !important; }
      .messenger .right-section .right-section-content {
        overflow: auto;
        height: auto !important;
        max-height: auto !important; }
    .messenger .middle-section .driver-info {
      padding: 10px; }
    .messenger .middle-section .messages-box {
      border: none !important; }
    .messenger .middle-section .input-message-container {
      background-color: white;
      padding: 10px;
      border-top: 1.5px solid #ebe7e7; }
      .messenger .middle-section .input-message-container .btn-file .custom-file {
        position: absolute;
        top: 0;
        left: 0;
        height: 100px !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: pointer; }
        .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-input {
          height: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
        .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-label {
          height: 100% !important;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
          .messenger .middle-section .input-message-container .btn-file .custom-file .custom-file-label:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
            outline: none !important;
            box-shadow: none !important; }
  .messenger-2 {
    padding: 0 !important; }
    .messenger-2 .left-section {
      background-color: white;
      padding: 10px !important; }
    .messenger-2 .right-section {
      background-color: white;
      padding: 10px !important; }
      .messenger-2 .right-section .right-section-content {
        overflow: auto;
        height: auto !important;
        max-height: auto !important; }
    .messenger-2 .middle-section .driver-info {
      padding: 10px; }
    .messenger-2 .middle-section .messages-box {
      border: none !important; }
    .messenger-2 .middle-section .input-message-container {
      background-color: white;
      padding: 10px;
      border-top: 1.5px solid #ebe7e7; }
      .messenger-2 .middle-section .input-message-container .btn-file .custom-file {
        position: absolute;
        top: 0;
        left: 0;
        height: 100px !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        background-color: transparent !important;
        cursor: pointer; }
        .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-input {
          height: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
        .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-label {
          height: 100% !important;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important; }
          .messenger-2 .middle-section .input-message-container .btn-file .custom-file .custom-file-label:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
            outline: none !important;
            box-shadow: none !important; } }

.fade-in {
  animation-name: fade-in;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-out {
  animation-name: fade-out;
  animation-duration: 0.5s;
  animation-iteration-count: 1; }

@keyframes fade-out {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.close-now {
  animation-name: close-now !important;
  animation-duration: 0.4s !important;
  animation-iteration-count: 1 !important; }

@keyframes close-now {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-100%, 0); } }

.circle-loader {
  position: fixed;
  bottom: 20px;
  left: 20px; }
  .circle-loader .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px; }
  .circle-loader .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #707070;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #707070 transparent transparent transparent; }
  .circle-loader .lds-ring div:nth-child(1) {
    animation-delay: -0.45s; }
  .circle-loader .lds-ring div:nth-child(2) {
    animation-delay: -0.3s; }
  .circle-loader .lds-ring div:nth-child(3) {
    animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loading-feedback {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-feedback h3 {
    animation-name: text-beating;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    color: white; }

@keyframes text-beating {
  0% {
    color: white; }
  50% {
    color: rgba(255, 255, 255, 0.5); }
  100% {
    color: white; } }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #707070;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #707070 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.bp3-portal {
  z-index: 9999999 !important; }

::-webkit-scrollbar {
  width: 10px;
  /* for vertical scrollbars */
  height: 6px;
  /* for horizontal scrollbars */ }

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); }

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }
